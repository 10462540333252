<!-- <app-header></app-header> -->
<router-outlet></router-outlet>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
<div id="attractions" class="container my-5">
    <div class="row">
        <!-- Left Side -->
        <div class="col-md-5 col-12 mb-4 d-flex align-items-center justify-content-center flex-column text-center">
            <h1 class="explore-heading">EXPLORE</h1>
            <h1 class="explore-subheading">THE CITY</h1>
            <p class="description">
                Our knowledgeable concierge team can assist you in planning your itinerary and arranging tours, tickets, and transportation.
            </p>
            <a (click)="scrollToSection('location')" class="contact-button btn btn-primary">Contact Us</a>
        </div>

        <!-- Right Side (Grid of Images) -->
        <!-- {{this.token?.getProperty()?.address?.city}} -->
        <div class="col-md-7 col-12">
          <main *ngIf="blogPosts$ | async as blogPosts">
            <div *ngFor="let blogPost of blogPosts.items; let i = index">
            <div class="row" >
              <ng-container *ngFor="let item of blogPost.fields.citiesimages; let j = index">
              <div  class="col-md-6 col-12 mb-4" *ngIf="j < 4 && blogPost.fields.title === this.tokenStorage?.getProperty()?.address?.city">

                  <div *ngIf="blogPost.fields.title === this.tokenStorage?.getProperty()?.address?.city ">

                      <div class="card" style="z-index: 1; margin-bottom: -40px; border-radius: 15px;">
                        <img [src]="item.fields.file.url" class="card-img-top" style="border-radius: 15px;height:200px" alt="This Temple">
                      </div>
                      <div class="card-body text-center card-body-style" style="background-color: var(--primary) !important; margin-left: -10px; margin-right: -10px; border-radius: 10px; padding-top: 50px;">
                        <h5 class="card-title">{{ item.fields.title }}</h5>
                      </div>

                  </div>

              </div>
              </ng-container >
            </div>

          </div>
          </main>
        </div>


            </div>
</div>

<div class="nearby-places-section">
  <h2 class="section-heading">Nearby Places</h2>
  <div class="unique-cards-container d-none d-md-flex">
    <div class="card unique-card">
      <img src="assets/images/water park.jpg" class="card-img-top" alt="Water Park">
      <div class="card-body">
        <p class="card-text">Water Park</p>
      </div>
    </div>
    <div class="card unique-card">
      <img src="assets/images/Theme Park.jpg" class="card-img-top" alt="Theme Park">
      <div class="card-body">
        <p class="card-text">Theme Park</p>
      </div>
    </div>
    <div class="card unique-card">
      <img src="assets/images/Khandoba Temple.jpg" class="card-img-top" alt="Khandoba Temple">
      <div class="card-body">
        <p class="card-text">Khandoba Temple</p>
      </div>
    </div>
    <div class="card unique-card">
      <img src="assets/images/Panchmukhi Ganesh Temple.jpg" class="card-img-top" alt="Panchmukhi Ganesh Temple">
      <div class="card-body">
        <p class="card-text">Panchmukhi Ganesh Temple</p>
      </div>
    </div>
    <div class="card unique-card">
      <img src="assets/images/Baba Ka Chawri.jpg" class="card-img-top" alt="Baba Ka Chawri">
      <div class="card-body">
        <p class="card-text">Baba Ka Chawri</p>
      </div>
    </div>
  </div>
</div>




<div class="unique-carousel-wrapper d-block d-md-none">
  <h2 class="section-heading">Nearby Places</h2>
  <div class="unique-carousel-card shadow-lg border-0 rounded overflow-hidden">
    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner unique-carousel-inner">
        <!-- Loop through the items using Angular's *ngFor -->
        <div
          *ngFor="let item of carouselItems; let i = index"
          class="carousel-item"
          [ngClass]="{ 'active': i === 0 }"
        >
          <div class="unique-carousel-item-content text-center p-3">
            <div class="unique-carousel-item-card">
              <img [src]="item.imgSrc" [alt]="item.title" class="img-fluid circular-image">
              <h2 class="mt-3">{{ item.title }}</h2>
              <p>{{ item.age }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Carousel Controls -->
      <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</div>









<div class="container my-5">
  <div class="row" id="testimonials">
    <!-- First Column -->
    <div class="col-md-4 mb-4 d-flex flex-column justify-content-center align-items-start">
      <h2 class="headline font-weight-bold mb-3">Real Stays, Real Talk</h2>
      <p class="intro-text mb-4 text-muted">Here's what our guests are saying...</p>
      <a href="https://www.google.com/travel/search?q=hotel%20saikrupa%20shirdi&g2lb=4814050%2C4874190%2C4893075%2C4965990%2C4969803%2C72277293%2C72302247%2C72317059%2C72406588%2C72414906%2C72421566%2C72471280%2C72472051%2C72473841%2C72481459%2C72485658%2C72536387%2C72602734%2C72614662%2C72616120%2C72619927%2C72647020%2C72648289%2C72658035%2C72671093%2C72686036%2C72729609%2C72749231%2C72757849%2C72760536%2C72766791&hl=en-IN&gl=in&cs=1&ssta=1&ts=CAEaRwopEicyJTB4M2JkYzViYmU1ODdlOWMwYjoweGRhOGNjZjVhMzBmNDA0ZDESGhIUCgcI6A8QCxgBEgcI6A8QCxgCGAEyAhAA&qs=CAEyFENnc0kwWW5RaDZQcnM4YmFBUkFCOAJCCQnRBPQwWs-M2kIJCdEE9DBaz4za&ap=ugEHcmV2aWV3cw&ictx=111&ved=0CAAQ5JsGahcKEwiA4rXR_peJAxUAAAAAHQAAAAAQEA" class="btn btn-primary btn-lg writereview">
      <div style="padding-left: 10px;padding-right: 10px;">
        Write a Review
      </div>
    </a>
    </div>

    <!-- Second Column (Carousel for Desktop) -->
    <div class="col-md-8 mb-4  d-md-block">
      <div class="card shadow-sm border-0 h-100">
        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner style_carasol" style="display: block;" >
                        <div
              class="carousel-item" data-interval="10000"
              *ngFor="let item of googleReviews; let i = index"
              [ngClass]="{ 'active': i === 0 }"
            >
              <div class="row">
                <div class="col-md-12 d-flex align-items-center">
                  <div class="testimonial p-3">


                       <div style="text-align: center;">
                        <div>
                          <img
                          src="assets/images/test.png"
                          alt="{{ item.reviewerName }}"
                          class="rounded-circle img-fluid shadow-lg mb-3"
                          style="width: 80px; height: 80px;"
                        />
                        <br/>
                        </div>

                       <div>
                        <h5 class="font-weight-bold mb-1">{{ item.reviewerName }}</h5>
                       </div>
                      <div>
                        <p class="small text-muted mb-2 review-text">{{ item.reviewText }}</p>
                      </div>

                        <div class="star-ratings text-warning">
                          {{ '★'.repeat(item.rating) }}
                        </div>
                       </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <!-- Carousel Controls Positioned Outside -->
      <div class="carousel-controls">
        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>




  </div>
</div>






  <div id="location" style="margin-bottom: 2px;">
  <div style="position: relative; width: 100%; max-width: 100%; height: 600px;">
    <!-- The iframe -->
    <iframe src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d120152.56890185545!2d74.39239869881317!3d19.765018542825192!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3bdc5bbe587e9c0b%3A0xda8ccf5a30f404d1!2sSai%20Nagar%2C%20Shirdi%2C%20Maharashtra%20423109!3m2!1d19.7650374!2d74.47480039999999!5e0!3m2!1sen!2sin!4v1729254217476!5m2!1sen!2sin" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
<div   class="card" style="position: absolute; top: 50px; text-align: center; left: 50px; background-color: white; border-bottom-left-radius: 61px;box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-top-right-radius: 59px;
    padding: 50px;">
        <h3>Make Unforgettable Memories</h3>
        <p>We're here to assist you with any questions, requests, or bookings.<br> Please don't hesitate to reach out to us.</p>
        <button class="book-btn" (click)="navigate()">Book Your Stay</button>
        <p class="contact-footer">
          <i class="fa fa-phone"></i> {{property?.mobile}}
        </p>
        <p class="contact-footer">
          <i class="fa fa-envelope"></i> {{property?.email}}
        </p>

      </div>

  </div>
</div>
<div class="chatbot-hello" id="chatbotGreeting">
  <img src="assets/images/hand wave.gif" alt="Hello Character" />
  <span class="message">Hello! How can I help you?</span>
</div>

<!-- Dialogflow Messenger Integration -->

<df-messenger project-id="impactful-name-306810" agent-id="8bb6c52c-8667-4a24-a789-4cd78b154c96" intent="WEBSITE" storage-option="none" language-code="en" max-query-length="-1"   >
  <df-messenger-chat-bubble  id="chatbot" chat-subtitle=""></df-messenger-chat-bubble>
</df-messenger>
<a [href]="getWhatsappShareUrl()" class="float" target="_blank" >
  <i class="fa fa-whatsapp my-float"></i>
</a>

<app-footer></app-footer>

